@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700;800&display=swap");
@import url(https://fonts.googleapis.com/css?family=Roboto+Mono:100,200,300,regular,500,600,700,100italic,200italic,300italic,italic,500italic,600italic,700italic);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  background-color: white;
  font-family: Poppins, sans-serif;
  font-size: 1.5em;
  -webkit-font-smoothing: antialiased;
  font-smooth: always;
  overflow-x: hidden;
  min-width: auto;
}
@media screen and (max-width: 800px) {
  body {
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
  }
}

a {
  text-decoration: none;
  color: inherit;
}
a:hover {
  text-decoration: underline;
}

.nav-bar {
  width: 100vw;
  height: 6rem;
  top: 0;
  z-index: 100;
  position: absolute;
  transition: 0.5s ease;
  padding: 0 15vw 0 15vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nav-bar.gone {
  position: fixed;
  background-color: rgba(34, 35, 43, 0.8156862745);
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(10px);
  box-shadow: rgba(28, 27, 28, 0.387) 0px 12px 29px 0px;
}
.nav-bar .logo a {
  font-size: 1.3em;
  text-decoration: none;
}
.nav-bar .links {
  display: flex;
}
@media screen and (max-width: 1000px) {
  .nav-bar .links {
    display: none !important;
  }
}
.nav-bar .links a {
  margin: 1rem;
  text-decoration: none;
  font-size: 0.95em;
  transition: 0.3s ease;
  display: flex;
  align-items: center;
}
.nav-bar .links a svg {
  position: relative;
  transition: 0.3s ease;
  margin-right: 0.5rem;
}
.nav-bar .links a:hover {
  transform: scale(1.07);
}
.nav-bar .links a:hover svg {
  transform: scale(1.13);
}

@media screen and (max-width: 1000px) {
  .btn::before {
    display: none;
  }
}
.base {
  background-color: #22232B;
  color: white;
}

html,
body {
  overflow-x: hidden;
  width: 100%;
  min-height: 100%;
  position: relative;
}

.content {
  display: grid;
  position: static !important;
}
.content .svgTransition {
  margin-top: 5rem;
  width: 100%;
  min-width: 1500px;
  transform: translateY(10px);
}
@media screen and (max-width: 800px) {
  .content .vanta-canvas {
    display: none;
  }
}
.content .content-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 15vw;
  padding-right: 15vw;
  height: calc(100vh + 15px);
}
.content .content-text h1 {
  margin-bottom: 0.2em;
  font-size: clamp(2.6em, 10vmin, 3em);
  letter-spacing: 0ch;
  font-weight: bold;
  line-height: 1.3;
}
.content .content-text h1 span {
  cursor: pointer;
  display: inline-block;
  transition: transform 0.4s cubic-bezier(0.8, -0.5, 0.2, 1.4);
  transform: rotate(0deg);
}
.content .content-text h1 span::after {
  content: "";
  position: absolute;
  transform: translate(-0.35ch, -0.37ch) rotate(-3.06deg);
  display: block;
  width: 3.5ch;
  margin-left: 0.5ch;
  padding-bottom: 5px;
  border-bottom: 0.15em solid #aa57fd;
}
.content .content-text h1 span:hover {
  transform: rotate(20deg);
  text-decoration: none;
}
.content .content-text .text {
  width: max(70%, 50rem);
}
.content .content-text .text h4 {
  font-weight: 400;
  width: 80vw;
  font-size: clamp(0.7em, 6vmin, 1em);
  margin-bottom: 3vh;
}
.content .content-text .text p {
  width: min(80vw, 650px);
  word-wrap: break-word;
  font-size: 0.8em;
}
.content .content-text .btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4f1db8;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  margin: 0.7em 0;
  padding: 0.5rem;
  border-radius: 0.5rem;
  transition: all 0.25s ease-out;
  box-shadow: rgba(19, 19, 19, 0.168627451) 0px 0px 1rem 0.5rem;
  position: relative;
  text-decoration: none;
}
.content .content-text .btn .iconify--carbon {
  margin-right: 0.2em;
}
@media (hover: hover) {
  .content .content-text .btn:hover {
    transition-timing-function: ease;
    background-color: #fa8956;
    box-shadow: rgba(46, 43, 46, 0.2274509804) 0px 0px 1.5rem 0.8rem;
    transform: scale(1.03);
  }
}
.content .content-text .btn::before {
  content: url("../imgs/Arrow 1.svg");
  position: absolute;
  cursor: default;
  pointer-events: none;
  left: calc(6vw - 700px);
}
@media screen and (max-width: 1000px) {
  .content .content-text .btn::before {
    display: none;
  }
}
@media screen and (min-width: 2200px) {
  .content .content-text .btn::before {
    left: calc(-450px - 5vw);
  }
}
@media screen and (min-width: 2350px) {
  .content .content-text .btn::before {
    left: calc(5vw - 800px);
  }
}
@media screen and (max-width: 1300px) {
  .content .content-text .btn::before {
    left: -600px;
  }
}
.content .content-text .btn p {
  margin: 0.2rem;
}

.projects {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100vw;
}
.projects h1 {
  margin-top: 1em;
  font-size: 2em;
  padding: 0.5em;
  font-family: "Roboto Mono";
  position: relative;
}
.projects h1::before {
  content: "";
  position: absolute;
  top: 80%;
  display: inline-block;
  background-color: #aa57fd;
  width: 4ch;
  height: 5px;
}
.projects h1::after {
  content: "Projects";
  font-family: Poppins;
  position: absolute;
  display: inline-block;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  z-index: 100;
  font-size: 2.1em;
  bottom: 0.2em;
  right: 0.7vw;
  opacity: 0.1;
}
.projects .projects-container {
  display: -ms-grid;
  display: grid;
  padding: 0 10vw 10vw 10vw;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}
@media screen and (min-width: 1500px) {
  .projects .projects-container {
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  }
}
@media screen and (max-width: 400px) {
  .projects .projects-container {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}
.projects .projects-container .project div div a {
  color: #aa57fd;
}
.projects .projects-container .project:hover {
  transform: scale(1.03);
}

footer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  background-color: rgb(231, 231, 231);
  color: black;
  padding: 2rem 10vw;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
footer svg.iconify.iconify--bx {
  cursor: pointer;
  display: inline;
  margin: 0 0.5em;
  color: black;
  transition-duration: 0.5s;
}
footer svg.iconify.iconify--bx.clicked {
  color: #aa57fd;
  -webkit-animation: 2s bounce;
          animation: 2s bounce;
}/*# sourceMappingURL=Style.css.map */