// @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;0,900;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700;800&display=swap');
@import url(https://fonts.googleapis.com/css?family=Roboto+Mono:100,200,300,regular,500,600,700,100italic,200italic,300italic,italic,500italic,600italic,700italic);

$primary-color: #aa57fd;
$main-padding: 15vw;
$navHeight: 6rem;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  // overflow-x: hidden;
}

body {
  background-color: white;
  font-family: Poppins, sans-serif;
  font-size: 1.5em;
  -webkit-font-smoothing: antialiased;
  font-smooth: always;
  overflow-x: hidden;
  min-width: auto;

  @media screen and (max-width: 800px) {
    width: -moz-fit-content;
    width: fit-content;
  }
}

a {
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: underline;
  }
}

.nav-bar {
  width: 100vw;
  height: $navHeight;
  top: 0;
  // position: relative;
  z-index: 100;
  position: absolute;
  transition: 0.5s ease;

  &.gone {
    position: fixed;
    background-color: #22232bd0;
    -webkit-backdrop-filter: blur(50px);
    backdrop-filter: blur(10px);
    box-shadow: rgba(28, 27, 28, 0.387) 0px 12px 29px 0px;
  }

  padding: 0 $main-padding 0 $main-padding;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .logo a {
    font-size: 1.3em;
    text-decoration: none;
  }

  .links {
    display: flex;

    @media screen and (max-width: 1000px) {
      display: none !important;
    }


    a {
      margin: 1rem;
      text-decoration: none;
      font-size: 0.95em;

      transition: 0.3s ease;

      display: flex;
      align-items: center;

      svg {
        position: relative;
        transition: 0.3s ease;
        // top: 0.15rem;
        margin-right: 0.5rem;
      }

      &:hover {
        transform: scale(1.07);

        svg {
          transform: scale(1.13);
        }
      }
    }
  }
}

// @media screen and (max-width: 1200px) {
//   .content-img {
//     display: none;
//   }
// }

@media screen and (max-width: 1000px) {
  .btn::before {
    display: none;
  }
}

.base {
  background-color: #22232B;
  color: white;
}

html,
body {
  overflow-x: hidden;
  width: 100%;
  min-height: 100%;
  position: relative;
}

.content {
  display: grid;
  position: static !important;

  .svgTransition {
    margin-top: 5rem;
    width: 100%;
    min-width: 1500px;
    transform: translateY(10px);
  }

  .vanta-canvas {
    @media screen and (max-width: 800px) {
      display: none;
    }
  }

  .content-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: $main-padding;
    padding-right: $main-padding;

    height: calc(100vh + 15px); // small offset

    h1 {
      margin-bottom: 0.2em;
      font-size: clamp(2.6em, 10vmin, 3em);
      letter-spacing: 0ch;
      font-weight: bold;
      line-height: 1.3;

      span {
        cursor: pointer;
        display: inline-block;
        transition: transform 0.4s cubic-bezier(0.8, -0.5, 0.2, 1.4);
        transform: rotate(0deg);

        &::after {
          content: "";
          position: absolute;
          transform: translate(-0.35ch, -0.37ch) rotate(-3.06deg);
          // bottom: 0.3ch;
          // right: 0.45ch;
          display: block;
          width: 3.5ch;
          margin-left: 0.5ch;
          padding-bottom: 5px;
          border-bottom: 0.15em solid $primary-color;
        }

        &:hover {
          transform: rotate(20deg);
          text-decoration: none;
        }
      }
    }

    .text {
      width: max(70%, 50rem);

      h4 {
        font-weight: 400;
        width: 80vw;
        font-size: clamp(0.7em, 6vmin, 1em);

        margin-bottom: 3vh;
      }

      p {
        width: min(80vw, 650px);
        word-wrap: break-word;
        font-size: 0.8em;
      }
    }



    .btn {
      display: flex;
      justify-content: center;
      align-items: center;

      background-color: #4f1db8;

      width: -moz-fit-content;
      width: fit-content;

      margin: 0.7em 0;
      padding: 0.5rem;

      border-radius: 0.5rem;

      transition: all 0.25s ease-out;
      box-shadow: #1313132b 0px 0px 1rem 0.5rem;

      position: relative;

      .iconify--carbon {
        margin-right: 0.2em;
      }

      @media (hover: hover) {
        &:hover {
          transition-timing-function: ease;
          background-color: #fa8956;
          box-shadow: #2e2b2e3a 0px 0px 1.5rem 0.8rem;
          transform: scale(1.03);
        }
      }


      &::before {
        @media screen and (max-width: 1000px) {
          display: none;
        }

        content: url('../imgs/Arrow\ 1.svg');
        position: absolute;

        @media screen and (min-width: 2200px) {
          left: calc(-450px - 5vw);
        }

        @media screen and (min-width: 2350px) {
          left: calc(5vw - 800px);
        }

        @media screen and (max-width: 1300px) {
          left: calc(-600px);
        }

        cursor: default;
        pointer-events: none;
        left: calc(6vw - 700px);
      }

      text-decoration: none;

      p {
        margin: 0.2rem;
      }
    }
  }
}

.projects {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100vw;

  h1 {
    margin-top: 1em;
    font-size: 2em;
    padding: 0.5em;
    font-family: "Roboto Mono";
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 80%;
      display: inline-block;
      background-color: $primary-color;
      width: 4ch;
      height: 5px;
    }

    &::after {
      content: "Projects"; // watermark
      font-family: Poppins;
      position: absolute;
      display: inline-block;
      width: max-content;
      z-index: 100;
      font-size: 2.1em;
      bottom: 0.2em;
      right: 0.7vw;
      opacity: 0.1;
    }
  }

  .projects-container {
    display: -ms-grid;
    display: grid;
    padding: 0 10vw 10vw 10vw;

    @media screen and (min-width: 1500px) {
      grid-template-columns: repeat(auto-fit, minmax(calc(350px), 1fr));
    }

    @media screen and (max-width: 400px) {
      grid-template-columns: repeat(auto-fit, minmax(calc(250px), 1fr));
    }

    grid-template-columns: repeat(auto-fit, minmax(calc(300px), 1fr));

    .project {
      div div a {
        color: $primary-color;
      }
    }

    .project:hover {
      transform: scale(1.03);
    }
  }
}

footer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  background-color: rgb(231, 231, 231);
  color: black;
  padding: 2rem 10vw;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */

  svg.iconify.iconify--bx {
    cursor: pointer;
    display: inline;
    margin: 0 0.5em;
    color: black;
    transition-duration: 0.5s;

    &.clicked {
      color: $primary-color;
      animation: 2s bounce;
    }
  }
}